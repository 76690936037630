import { graphql, PageProps } from "gatsby"
import React, { useEffect } from "react"
import Layout from "../components/layout"

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface NotFoundPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string
      }
    }
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`

const NotFoundPage: React.FC<NotFoundPageProps> = ({ children }) => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "404",
        page_path: "/404",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])

  return <Layout>404 Page not found</Layout>
}

export default NotFoundPage
